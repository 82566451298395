.flatpickr-calendar {
  opacity: 0;
  text-align: center;
  visibility: hidden;
  direction: ltr;
  width: 307.875px;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  border: 0;
  border-radius: 5px;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  animation: none;
  display: none;
  position: absolute;
  box-shadow: 1px 0 #e6e6e6, -1px 0 #e6e6e6, 0 1px #e6e6e6, 0 -1px #e6e6e6, 0 3px 13px rgba(0, 0, 0, .08);
}

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  z-index: 99999;
  display: inline-block;
}

.flatpickr-calendar.animate.open {
  animation: .3s cubic-bezier(.23, 1, .32, 1) fpFadeInDown;
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
  pointer-events: none;
  content: "";
  height: 0;
  width: 0;
  border: solid rgba(0, 0, 0, 0);
  display: block;
  position: absolute;
  left: 22px;
}

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.arrowRight:before, .flatpickr-calendar.rightMost:after, .flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before, .flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}

.flatpickr-months {
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  color: rgba(0, 0, 0, .9);
  fill: rgba(0, 0, 0, .9);
  height: 34px;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  -ms-flex: 1;
  flex: 1;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  height: 34px;
  z-index: 3;
  color: rgba(0, 0, 0, .9);
  fill: rgba(0, 0, 0, .9);
  padding: 10px;
  text-decoration: none;
  position: absolute;
  top: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  fill: inherit;
  transition: fill .1s;
}

.numInputWrapper {
  height: auto;
  position: relative;
}

.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numInputWrapper span {
  width: 14px;
  height: 50%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid rgba(57, 57, 57, .15);
  padding: 0 4px 0 2px;
  line-height: 50%;
  position: absolute;
  right: 0;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, .1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, .2);
}

.numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}

.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}

.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid rgba(57, 57, 57, .6);
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-top: 4px solid rgba(57, 57, 57, .6);
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, .5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, .05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  color: inherit;
  width: 75%;
  height: 34px;
  text-align: center;
  padding: 7.48px 0 0;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
  position: absolute;
  left: 12.5%;
  transform: translate3d(0, 0, 0);
}

.flatpickr-current-month span.cur-month {
  color: inherit;
  margin-left: .5ch;
  padding: 0;
  font-family: inherit;
  font-weight: 700;
  display: inline-block;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, .05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch�;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, .9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, .9);
}

.flatpickr-current-month input.cur-year {
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  vertical-align: initial;
  -webkit-appearance: textfield;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0 0 0 .5ch;
  display: inline-block;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  color: rgba(0, 0, 0, .5);
  pointer-events: none;
  background: none;
  font-size: 100%;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  -webkit-appearance: menulist;
  appearance: menulist;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  height: auto;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  vertical-align: initial;
  width: auto;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  margin: -1px 0 0;
  padding: 0 0 0 .5ch;
  position: relative;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, .05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  text-align: center;
  width: 100%;
  height: 28px;
  background: none;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  -ms-flex: 1;
  flex: 1;
  display: -ms-flexbox;
  display: flex;
}

span.flatpickr-weekday {
  cursor: default;
  color: rgba(0, 0, 0, .54);
  text-align: center;
  background: none;
  -ms-flex: 1;
  flex: 1;
  margin: 0;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  display: block;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0;
}

.flatpickr-days {
  width: 307.875px;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  opacity: 1;
  outline: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  transform: translate3d(0, 0, 0);
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 #e6e6e6;
}

.flatpickr-day {
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  width: 14.2857%;
  max-width: 39px;
  height: 39px;
  text-align: center;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 150px;
  -ms-flex-preferred-size: 14.2857%;
  flex-basis: 14.2857%;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  font-weight: 400;
  line-height: 39px;
  display: inline-block;
  position: relative;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  background: #e6e6e6;
  border-color: #e6e6e6;
  outline: 0;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  color: #fff;
  background: #959ea9;
  border-color: #959ea9;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  background: #569ff7;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, .3);
  cursor: default;
  background: none;
  border-color: rgba(0, 0, 0, 0);
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, .1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 #569ff7, 5px 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, .3);
  cursor: default;
  background: none;
  border: none;
  display: block;
}

.flatpickr-innerContainer {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.flatpickr-rContainer {
  box-sizing: border-box;
  padding: 0;
  display: inline-block;
}

.flatpickr-time {
  text-align: center;
  height: 0;
  max-height: 40px;
  box-sizing: border-box;
  outline: 0;
  line-height: 40px;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.flatpickr-time:after {
  content: "";
  clear: both;
  display: table;
}

.flatpickr-time .numInputWrapper {
  width: 40%;
  height: 40px;
  float: left;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: inherit;
  line-height: inherit;
  color: #393939;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  position: relative;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  border: 0;
  outline: 0;
}

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  width: 2%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-flex-item-align: center;
  align-self: center;
  font-weight: bold;
}

.flatpickr-time .flatpickr-am-pm {
  width: 18%;
  cursor: pointer;
  text-align: center;
  outline: 0;
  font-weight: 400;
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.pswp {
  width: 100%;
  height: 100%;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 1500;
  -webkit-text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  outline: none;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.pswp * {
  box-sizing: border-box;
}

.pswp img {
  max-width: none;
}

.pswp--animate_opacity {
  opacity: .001;
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--open {
  display: block;
}

.pswp--zoom-allowed .pswp__img {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.pswp--zoomed-in .pswp__img {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.pswp--dragging .pswp__img {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.pswp__bg {
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(0);
}

.pswp__scroll-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.pswp__container, .pswp__zoom-wrap {
  -ms-touch-action: none;
  touch-action: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.pswp__container, .pswp__img {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.pswp__zoom-wrap {
  width: 100%;
  transform-origin: 0 0;
  -webkit-transition: -webkit-transform .333s cubic-bezier(.4, 0, .22, 1);
  transition: transform .333s cubic-bezier(.4, 0, .22, 1);
  position: absolute;
}

.pswp__bg {
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--animated-in .pswp__bg, .pswp--animated-in .pswp__zoom-wrap {
  transition: none;
}

.pswp__container, .pswp__zoom-wrap {
  -webkit-backface-visibility: hidden;
}

.pswp__item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.pswp__img {
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__img--placeholder {
  -webkit-backface-visibility: hidden;
}

.pswp__img--placeholder--blank {
  background: #222;
}

.pswp--ie .pswp__img {
  top: 0;
  left: 0;
  width: 100% !important;
  height: auto !important;
}

.pswp__error-msg {
  width: 100%;
  text-align: center;
  color: #ccc;
  margin-top: -8px;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 50%;
  left: 0;
}

.pswp__error-msg a {
  color: #ccc;
  text-decoration: underline;
}

.pswp__button {
  width: 44px;
  height: 44px;
  cursor: pointer;
  -webkit-appearance: none;
  float: right;
  opacity: .75;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  transition: opacity .2s;
  display: block;
  position: relative;
  overflow: visible;
}

.pswp__button:focus, .pswp__button:hover {
  opacity: 1;
}

.pswp__button:active {
  opacity: .9;
  outline: none;
}

.pswp__button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.pswp__ui--over-close .pswp__button--close {
  opacity: 1;
}

.pswp__button, .pswp__button--arrow--left:before, .pswp__button--arrow--right:before {
  width: 44px;
  height: 44px;
  background: url("default-skin.1a8c5431.png") 0 0 / 264px 88px no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 1.1), (-webkit-min-device-pixel-ratio: 1.09375), (min-resolution: 105dpi), (min-resolution: 1.1dppx) {
  .pswp--svg .pswp__button, .pswp--svg .pswp__button--arrow--left:before, .pswp--svg .pswp__button--arrow--right:before {
    background-image: url("default-skin.dca027d6.svg");
  }

  .pswp--svg .pswp__button--arrow--left, .pswp--svg .pswp__button--arrow--right {
    background: none;
  }
}

.pswp__button--close {
  background-position: 0 -44px;
}

.pswp__button--share {
  background-position: -44px -44px;
}

.pswp__button--fs {
  display: none;
}

.pswp--supports-fs .pswp__button--fs {
  display: block;
}

.pswp--fs .pswp__button--fs {
  background-position: -44px 0;
}

.pswp__button--zoom {
  background-position: -88px 0;
  display: none;
}

.pswp--zoom-allowed .pswp__button--zoom {
  display: block;
}

.pswp--zoomed-in .pswp__button--zoom {
  background-position: -132px 0;
}

.pswp--touch .pswp__button--arrow--left, .pswp--touch .pswp__button--arrow--right {
  visibility: hidden;
}

.pswp__button--arrow--left, .pswp__button--arrow--right {
  width: 70px;
  height: 100px;
  background: none;
  margin-top: -50px;
  position: absolute;
  top: 50%;
}

.pswp__button--arrow--left {
  left: 0;
}

.pswp__button--arrow--right {
  right: 0;
}

.pswp__button--arrow--left:before, .pswp__button--arrow--right:before {
  content: "";
  height: 30px;
  width: 32px;
  background-color: rgba(0, 0, 0, .3);
  position: absolute;
  top: 35px;
}

.pswp__button--arrow--left:before {
  background-position: -138px -44px;
  left: 6px;
}

.pswp__button--arrow--right:before {
  background-position: -94px -44px;
  right: 6px;
}

.pswp__counter, .pswp__share-modal {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pswp__share-modal {
  width: 100%;
  height: 100%;
  z-index: 1600;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  background: rgba(0, 0, 0, .5);
  padding: 10px;
  transition: opacity .25s ease-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__share-modal--hidden {
  display: none;
}

.pswp__share-tooltip {
  z-index: 1620;
  width: auto;
  -webkit-transition: -webkit-transform .25s;
  -webkit-backface-visibility: hidden;
  will-change: transform;
  background: #fff;
  border-radius: 2px;
  transition: transform .25s;
  display: block;
  position: absolute;
  top: 56px;
  right: 44px;
  transform: translateY(6px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .25);
}

.pswp__share-tooltip a {
  color: #000;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

.pswp__share-tooltip a:hover {
  color: #000;
  text-decoration: none;
}

.pswp__share-tooltip a:first-child {
  border-radius: 2px 2px 0 0;
}

.pswp__share-tooltip a:last-child {
  border-radius: 0 0 2px 2px;
}

.pswp__share-modal--fade-in {
  opacity: 1;
}

.pswp__share-modal--fade-in .pswp__share-tooltip {
  transform: translateY(0);
}

.pswp--touch .pswp__share-tooltip a {
  padding: 16px 12px;
}

a.pswp__share--facebook:before {
  content: "";
  width: 0;
  height: 0;
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  pointer-events: none;
  border: 6px solid rgba(0, 0, 0, 0);
  border-bottom-color: #fff;
  display: block;
  position: absolute;
  top: -12px;
  right: 15px;
}

a.pswp__share--facebook:hover {
  color: #fff;
  background: #3e5c9a;
}

a.pswp__share--facebook:hover:before {
  border-bottom-color: #3e5c9a;
}

a.pswp__share--twitter:hover {
  color: #fff;
  background: #55acee;
}

a.pswp__share--pinterest:hover {
  color: #ce272d;
  background: #ccc;
}

a.pswp__share--download:hover {
  background: #ddd;
}

.pswp__counter {
  height: 44px;
  color: #fff;
  opacity: .75;
  padding: 0 10px;
  font-size: 13px;
  line-height: 44px;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__caption {
  width: 100%;
  min-height: 44px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pswp__caption small {
  color: #bbb;
  font-size: 11px;
}

.pswp__caption__center {
  text-align: left;
  max-width: 420px;
  color: #ccc;
  margin: 0 auto;
  padding: 10px;
  font-size: 13px;
  line-height: 20px;
}

.pswp__caption--empty {
  display: none;
}

.pswp__caption--fake {
  visibility: hidden;
}

.pswp__preloader {
  width: 44px;
  height: 44px;
  opacity: 0;
  will-change: opacity;
  direction: ltr;
  margin-left: -22px;
  transition: opacity .25s ease-out;
  position: absolute;
  top: 0;
  left: 50%;
}

.pswp__preloader__icn {
  width: 20px;
  height: 20px;
  margin: 12px;
}

.pswp__preloader--active {
  opacity: 1;
}

.pswp__preloader--active .pswp__preloader__icn {
  background: url("preloader.9ad95bd8.gif") no-repeat;
}

.pswp--css_animation .pswp__preloader--active {
  opacity: 1;
}

.pswp--css_animation .pswp__preloader--active .pswp__preloader__icn {
  animation: .5s linear infinite clockwise;
}

.pswp--css_animation .pswp__preloader--active .pswp__preloader__donut {
  animation: 1s cubic-bezier(.4, 0, .22, 1) infinite donut-rotate;
}

.pswp--css_animation .pswp__preloader__icn {
  opacity: .75;
  width: 14px;
  height: 14px;
  background: none;
  margin: 0;
  position: absolute;
  top: 15px;
  left: 15px;
}

.pswp--css_animation .pswp__preloader__cut {
  width: 7px;
  height: 14px;
  position: relative;
  overflow: hidden;
}

.pswp--css_animation .pswp__preloader__donut {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  background: none;
  border: 2px solid #fff;
  border-color: #fff #fff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  border-radius: 50%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1024px) {
  .pswp__preloader {
    float: right;
    margin: 0;
    position: relative;
    top: auto;
    left: auto;
  }
}

@keyframes clockwise {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes donut-rotate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-140deg);
  }

  100% {
    transform: rotate(0);
  }
}

.pswp__ui {
  -webkit-font-smoothing: auto;
  visibility: visible;
  opacity: 1;
  z-index: 1550;
}

.pswp__top-bar {
  height: 44px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__caption, .pswp__top-bar, .pswp--has_mouse .pswp__button--arrow--left, .pswp--has_mouse .pswp__button--arrow--right {
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--has_mouse .pswp__button--arrow--left, .pswp--has_mouse .pswp__button--arrow--right {
  visibility: visible;
}

.pswp__top-bar, .pswp__caption {
  background-color: rgba(0, 0, 0, .5);
}

.pswp__ui--fit .pswp__top-bar, .pswp__ui--fit .pswp__caption {
  background-color: rgba(0, 0, 0, .3);
}

.pswp__ui--idle .pswp__top-bar, .pswp__ui--idle .pswp__button--arrow--left, .pswp__ui--idle .pswp__button--arrow--right {
  opacity: 0;
}

.pswp__ui--hidden .pswp__top-bar, .pswp__ui--hidden .pswp__caption, .pswp__ui--hidden .pswp__button--arrow--left, .pswp__ui--hidden .pswp__button--arrow--right {
  opacity: .001;
}

.pswp__ui--one-slide .pswp__button--arrow--left, .pswp__ui--one-slide .pswp__button--arrow--right, .pswp__ui--one-slide .pswp__counter {
  display: none;
}

.pswp__element--disabled {
  display: none !important;
}

.pswp--minimal--dark .pswp__top-bar {
  background: none;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e5e5;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Tahoma, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  color: #a3a3a3;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #a3a3a3;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #737373;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid rgba(0, 0, 0, 0);
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  color: #737373;
  opacity: 1;
}

input::placeholder, textarea::placeholder {
  color: #737373;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit, ::-webkit-datetime-edit-year-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23737373' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #737373;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: rgba(0, 0, 0, 0);
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: rgba(0, 0, 0, 0);
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: rgba(0, 0, 0, 0);
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: rgba(0, 0, 0, 0);
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 1792px) {
  .container {
    max-width: 1792px;
  }
}

.aspect-h-10 {
  --tw-aspect-h: 10;
}

.aspect-h-9 {
  --tw-aspect-h: 9;
}

.aspect-w-16 {
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 16;
  position: relative;
}

.aspect-w-16 > * {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(strong):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(blockquote strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(thead th strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(hr):not(:where([class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose :where(code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h1 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(blockquote code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(thead th code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"] *)) {
  vertical-align: top;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgba(0, 0, 0, .5);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
  padding: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose-sm {
  font-size: .875rem;
  line-height: 1.71429;
}

.prose-sm :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
}

.prose-sm :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
  font-size: 1.28571em;
  line-height: 1.55556;
}

.prose-sm :where(blockquote):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.11111em;
}

.prose-sm :where(h1):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .8em;
  font-size: 2.14286em;
  line-height: 1.2;
}

.prose-sm :where(h2):not(:where([class~="not-prose"] *)) {
  margin-top: 1.6em;
  margin-bottom: .8em;
  font-size: 1.42857em;
  line-height: 1.4;
}

.prose-sm :where(h3):not(:where([class~="not-prose"] *)) {
  margin-top: 1.55556em;
  margin-bottom: .444444em;
  font-size: 1.28571em;
  line-height: 1.55556;
}

.prose-sm :where(h4):not(:where([class~="not-prose"] *)) {
  margin-top: 1.42857em;
  margin-bottom: .571429em;
  line-height: 1.42857;
}

.prose-sm :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.prose-sm :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.prose-sm :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.prose-sm :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-sm :where(figcaption):not(:where([class~="not-prose"] *)) {
  margin-top: .666667em;
  font-size: .857143em;
  line-height: 1.33333;
}

.prose-sm :where(code):not(:where([class~="not-prose"] *)) {
  font-size: .857143em;
}

.prose-sm :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: .9em;
}

.prose-sm :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: .888889em;
}

.prose-sm :where(pre):not(:where([class~="not-prose"] *)) {
  border-radius: .25rem;
  margin-top: 1.66667em;
  margin-bottom: 1.66667em;
  padding: .666667em 1em;
  font-size: .857143em;
  line-height: 1.66667;
}

.prose-sm :where(ol):not(:where([class~="not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
  padding-left: 1.57143em;
}

.prose-sm :where(ul):not(:where([class~="not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
  padding-left: 1.57143em;
}

.prose-sm :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: .285714em;
  margin-bottom: .285714em;
}

.prose-sm :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: .428571em;
}

.prose-sm :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: .428571em;
}

.prose-sm :where(.prose-sm > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .571429em;
  margin-bottom: .571429em;
}

.prose-sm :where(.prose-sm > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.prose-sm :where(.prose-sm > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.14286em;
}

.prose-sm :where(.prose-sm > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.prose-sm :where(.prose-sm > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.14286em;
}

.prose-sm :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: .571429em;
  margin-bottom: .571429em;
}

.prose-sm :where(hr):not(:where([class~="not-prose"] *)) {
  margin-top: 2.85714em;
  margin-bottom: 2.85714em;
}

.prose-sm :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(table):not(:where([class~="not-prose"] *)) {
  font-size: .857143em;
  line-height: 1.5;
}

.prose-sm :where(thead th):not(:where([class~="not-prose"] *)) {
  padding-bottom: .666667em;
  padding-left: 1em;
  padding-right: 1em;
}

.prose-sm :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-sm :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-sm :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
  padding: .666667em 1em;
}

.prose-sm :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-sm :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-sm :where(.prose-sm > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(.prose-sm > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose-lg {
  font-size: 1.125rem;
  line-height: 1.77778;
}

.prose-lg :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.prose-lg :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  margin-top: 1.09091em;
  margin-bottom: 1.09091em;
  font-size: 1.22222em;
  line-height: 1.45455;
}

.prose-lg :where(blockquote):not(:where([class~="not-prose"] *)) {
  margin-top: 1.66667em;
  margin-bottom: 1.66667em;
  padding-left: 1em;
}

.prose-lg :where(h1):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .833333em;
  font-size: 2.66667em;
  line-height: 1;
}

.prose-lg :where(h2):not(:where([class~="not-prose"] *)) {
  margin-top: 1.86667em;
  margin-bottom: 1.06667em;
  font-size: 1.66667em;
  line-height: 1.33333;
}

.prose-lg :where(h3):not(:where([class~="not-prose"] *)) {
  margin-top: 1.66667em;
  margin-bottom: .666667em;
  font-size: 1.33333em;
  line-height: 1.5;
}

.prose-lg :where(h4):not(:where([class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: .444444em;
  line-height: 1.55556;
}

.prose-lg :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
}

.prose-lg :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
}

.prose-lg :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
}

.prose-lg :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-lg :where(figcaption):not(:where([class~="not-prose"] *)) {
  margin-top: 1em;
  font-size: .888889em;
  line-height: 1.5;
}

.prose-lg :where(code):not(:where([class~="not-prose"] *)) {
  font-size: .888889em;
}

.prose-lg :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: .866667em;
}

.prose-lg :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: .875em;
}

.prose-lg :where(pre):not(:where([class~="not-prose"] *)) {
  border-radius: .375rem;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1em 1.5em;
  font-size: .888889em;
  line-height: 1.75;
}

.prose-lg :where(ol):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.55556em;
}

.prose-lg :where(ul):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.55556em;
}

.prose-lg :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: .666667em;
  margin-bottom: .666667em;
}

.prose-lg :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: .444444em;
}

.prose-lg :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: .444444em;
}

.prose-lg :where(.prose-lg > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
}

.prose-lg :where(.prose-lg > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-lg :where(.prose-lg > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-lg :where(.prose-lg > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-lg :where(.prose-lg > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-lg :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
}

.prose-lg :where(hr):not(:where([class~="not-prose"] *)) {
  margin-top: 3.11111em;
  margin-bottom: 3.11111em;
}

.prose-lg :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(table):not(:where([class~="not-prose"] *)) {
  font-size: .888889em;
  line-height: 1.5;
}

.prose-lg :where(thead th):not(:where([class~="not-prose"] *)) {
  padding-bottom: .75em;
  padding-left: .75em;
  padding-right: .75em;
}

.prose-lg :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-lg :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-lg :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
  padding: .75em;
}

.prose-lg :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-lg :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-lg :where(.prose-lg > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(.prose-lg > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose-2xl {
  font-size: 1.5rem;
  line-height: 1.66667;
}

.prose-2xl :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.prose-2xl :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  margin-top: 1.06667em;
  margin-bottom: 1.06667em;
  font-size: 1.25em;
  line-height: 1.46667;
}

.prose-2xl :where(blockquote):not(:where([class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
  padding-left: 1.11111em;
}

.prose-2xl :where(h1):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .875em;
  font-size: 2.66667em;
  line-height: 1;
}

.prose-2xl :where(h2):not(:where([class~="not-prose"] *)) {
  margin-top: 1.5em;
  margin-bottom: .833333em;
  font-size: 2em;
  line-height: 1.08333;
}

.prose-2xl :where(h3):not(:where([class~="not-prose"] *)) {
  margin-top: 1.55556em;
  margin-bottom: .666667em;
  font-size: 1.5em;
  line-height: 1.22222;
}

.prose-2xl :where(h4):not(:where([class~="not-prose"] *)) {
  margin-top: 1.66667em;
  margin-bottom: .666667em;
  line-height: 1.5;
}

.prose-2xl :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-2xl :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-2xl :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-2xl :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-2xl :where(figcaption):not(:where([class~="not-prose"] *)) {
  margin-top: 1em;
  font-size: .833333em;
  line-height: 1.6;
}

.prose-2xl :where(code):not(:where([class~="not-prose"] *)) {
  font-size: .833333em;
}

.prose-2xl :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: .875em;
}

.prose-2xl :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: .888889em;
}

.prose-2xl :where(pre):not(:where([class~="not-prose"] *)) {
  border-radius: .5rem;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1.2em 1.6em;
  font-size: .833333em;
  line-height: 1.8;
}

.prose-2xl :where(ol):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.58333em;
}

.prose-2xl :where(ul):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.58333em;
}

.prose-2xl :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose-2xl :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: .416667em;
}

.prose-2xl :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: .416667em;
}

.prose-2xl :where(.prose-2xl > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .833333em;
  margin-bottom: .833333em;
}

.prose-2xl :where(.prose-2xl > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-2xl :where(.prose-2xl > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-2xl :where(.prose-2xl > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-2xl :where(.prose-2xl > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-2xl :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: .666667em;
  margin-bottom: .666667em;
}

.prose-2xl :where(hr):not(:where([class~="not-prose"] *)) {
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose-2xl :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-2xl :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-2xl :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-2xl :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-2xl :where(table):not(:where([class~="not-prose"] *)) {
  font-size: .833333em;
  line-height: 1.4;
}

.prose-2xl :where(thead th):not(:where([class~="not-prose"] *)) {
  padding-bottom: .8em;
  padding-left: .6em;
  padding-right: .6em;
}

.prose-2xl :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-2xl :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-2xl :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
  padding: .8em .6em;
}

.prose-2xl :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-2xl :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-2xl :where(.prose-2xl > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-2xl :where(.prose-2xl > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.default-form a {
  --tw-text-opacity: 1;
  color: rgba(34, 57, 142, var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.default-form a:hover {
  --tw-text-opacity: 1;
  color: rgba(25, 44, 122, var(--tw-text-opacity));
}

.default-form form p {
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.button {
  --tw-text-opacity: 1;
  color: rgba(82, 82, 82, var(--tw-text-opacity));
  outline-offset: 2px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: .375rem;
  outline: 2px solid rgba(0, 0, 0, 0);
  padding: .75rem 1rem;
}

.button:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(38, 38, 38, var(--tw-text-opacity));
}

.button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 149, 187, var(--tw-ring-opacity));
  outline: 2px solid rgba(0, 0, 0, 0);
}

button.is-sm {
  padding: .5rem .75rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

button.is-xs {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .75rem;
  line-height: 1rem;
}

.button-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 57, 142, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.button-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(25, 44, 122, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.button-primary.outlined {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(34, 57, 142, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(34, 57, 142, var(--tw-text-opacity));
  background-color: rgba(0, 0, 0, 0);
}

.button-primary.outlined:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 57, 142, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

label {
  --tw-text-opacity: 1;
  color: rgba(64, 64, 64, var(--tw-text-opacity));
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  display: block;
}

.default-form input[type="text"], .default-form input[type="email"], .default-form input[type="password"], .default-form input[type="date"], .default-form input[type="datetime-local"], .default-form input[type="month"], .default-form input[type="number"], .default-form textarea {
  width: 100%;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(212, 212, 212, var(--tw-border-opacity));
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  border-radius: .375rem;
  margin-top: .25rem;
  padding: .5rem .75rem;
  display: block;
}

.default-form input[type="text"]:focus, .default-form input[type="email"]:focus, .default-form input[type="password"]:focus, .default-form input[type="date"]:focus, .default-form input[type="datetime-local"]:focus, .default-form input[type="month"]:focus, .default-form input[type="number"]:focus, .default-form textarea:focus {
  --tw-border-opacity: 1;
  border-color: rgba(34, 57, 142, var(--tw-border-opacity));
  outline-offset: 2px;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 57, 142, var(--tw-ring-opacity));
  outline: 2px solid rgba(0, 0, 0, 0);
}

@media (min-width: 640px) {
  .default-form input[type="text"], .default-form input[type="email"], .default-form input[type="password"], .default-form input[type="date"], .default-form input[type="datetime-local"], .default-form input[type="month"], .default-form input[type="number"], .default-form textarea {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.ts-wrapper.plugin-remove_button .item {
  border-radius: .375rem;
}

.ts-wrapper.multi .ts-control > div {
  margin: 0;
  padding: 0 0 0 .25rem;
}

.ts-wrapper.plugin-remove_button .item .remove {
  border-style: none;
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 1.125rem;
  line-height: 1;
}

.ts-dropdown [data-selectable].option:first-child {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.ts-dropdown [data-selectable].option:last-child {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.ts-dropdown .create:hover, .ts-dropdown .option:hover, .ts-dropdown .active {
  --tw-bg-opacity: 1;
  background-color: rgba(205, 213, 229, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(15, 28, 96, var(--tw-text-opacity));
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.\!visible {
  visibility: visible !important;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-right-2 {
  right: -.5rem;
}

.-top-4 {
  top: -1rem;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.isolate {
  isolation: isolate;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.order-first {
  -ms-flex-order: -9999;
  order: -9999;
}

.order-last {
  -ms-flex-order: 9999;
  order: 9999;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.-mx-1\.5 {
  margin-left: -.375rem;
  margin-right: -.375rem;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.-my-1 {
  margin-top: -.25rem;
  margin-bottom: -.25rem;
}

.-my-1\.5 {
  margin-top: -.375rem;
  margin-bottom: -.375rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-mb-14 {
  margin-bottom: -3.5rem;
}

.-mb-2 {
  margin-bottom: -.5rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-0 {
  margin-left: 0;
}

.-ml-0\.5 {
  margin-left: -.125rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-ml-px {
  margin-left: -1px;
}

.-mr-0 {
  margin-right: 0;
}

.-mr-0\.5 {
  margin-right: -.125rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-32 {
  margin-top: -8rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-auto {
  margin-top: auto;
}

.line-clamp-4 {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: -ms-flexbox;
  display: flex;
}

.inline-flex {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-3 {
  height: .75rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.min-h-0 {
  min-height: 0;
}

.min-h-\[70vh\] {
  min-height: 70vh;
}

.min-h-\[75vh\] {
  min-height: 75vh;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-2\/5 {
  width: 40%;
}

.w-3 {
  width: .75rem;
}

.w-3\/5 {
  width: 60%;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-8 {
  width: 2rem;
}

.w-full {
  width: 100%;
}

.min-w-0 {
  min-width: 0;
}

.min-w-\[10rem\] {
  min-width: 10rem;
}

.min-w-full {
  min-width: 100%;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-1 {
  -ms-flex: 1;
  flex: 1;
}

.flex-shrink-0, .shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.flex-grow {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.basis-full {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.origin-top-left {
  transform-origin: 0 0;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-help {
  cursor: help;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.break-inside-avoid {
  break-inside: avoid;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.flex-row {
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-col {
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.items-start {
  -ms-flex-align: start;
  align-items: flex-start;
}

.items-end {
  -ms-flex-align: end;
  align-items: flex-end;
}

.items-center {
  -ms-flex-align: center;
  align-items: center;
}

.justify-start {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-end {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-between {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-1\.5 {
  column-gap: .375rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-y-3 {
  row-gap: .75rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}

.divide-\[\#505152\] > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(80, 81, 82, var(--tw-divide-opacity));
}

.divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-divide-opacity));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(229, 229, 229, var(--tw-divide-opacity));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(212, 212, 212, var(--tw-divide-opacity));
}

.divide-gray-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgba(23, 23, 23, .1);
}

.divide-red-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
}

.divide-red-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
}

.self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.self-center {
  -ms-flex-item-align: center;
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-bl-md {
  border-bottom-left-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 229, 229, var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(212, 212, 212, var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(163, 163, 163, var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(115, 115, 115, var(--tw-border-opacity));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(64, 64, 64, var(--tw-border-opacity));
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(23, 23, 23, var(--tw-border-opacity));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}

.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}

.border-khs-300 {
  --tw-border-opacity: 1;
  border-color: rgba(131, 149, 187, var(--tw-border-opacity));
}

.border-khs-500 {
  --tw-border-opacity: 1;
  border-color: rgba(34, 57, 142, var(--tw-border-opacity));
}

.border-khs-900 {
  --tw-border-opacity: 1;
  border-color: rgba(15, 28, 96, var(--tw-border-opacity));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.border-transparent {
  border-color: rgba(0, 0, 0, 0);
}

.bg-\[\#212935\] {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 41, 53, var(--tw-bg-opacity));
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}

.bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}

.bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(115, 115, 115, var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}

.bg-khs-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(205, 213, 229, var(--tw-bg-opacity));
}

.bg-khs-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 181, 208, var(--tw-bg-opacity));
}

.bg-khs-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 149, 187, var(--tw-bg-opacity));
}

.bg-khs-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(90, 116, 166, var(--tw-bg-opacity));
}

.bg-khs-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 233, 243, var(--tw-bg-opacity));
}

.bg-khs-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 57, 142, var(--tw-bg-opacity));
}

.bg-khs-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 52, 135, var(--tw-bg-opacity));
}

.bg-khs-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(25, 44, 122, var(--tw-bg-opacity));
}

.bg-khs-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 36, 109, var(--tw-bg-opacity));
}

.bg-khs-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(15, 28, 96, var(--tw-bg-opacity));
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.bg-warmgray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 249, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 249, 195, var(--tw-bg-opacity));
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 252, 232, var(--tw-bg-opacity));
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-opacity-90 {
  --tw-bg-opacity: .9;
}

.fill-blue-600 {
  fill: #2563eb;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-0\.5 {
  padding-bottom: .125rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.font-sans {
  font-family: Tahoma, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[0\.6rem\] {
  font-size: .6rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-tighter {
  letter-spacing: -.05em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}

.text-amber-600 {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}

.text-amber-800 {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 229, 229, var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(212, 212, 212, var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(163, 163, 163, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(115, 115, 115, var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(82, 82, 82, var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(64, 64, 64, var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(38, 38, 38, var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(23, 23, 23, var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}

.text-khs-100 {
  --tw-text-opacity: 1;
  color: rgba(205, 213, 229, var(--tw-text-opacity));
}

.text-khs-200 {
  --tw-text-opacity: 1;
  color: rgba(168, 181, 208, var(--tw-text-opacity));
}

.text-khs-400 {
  --tw-text-opacity: 1;
  color: rgba(90, 116, 166, var(--tw-text-opacity));
}

.text-khs-500 {
  --tw-text-opacity: 1;
  color: rgba(34, 57, 142, var(--tw-text-opacity));
}

.text-khs-600 {
  --tw-text-opacity: 1;
  color: rgba(30, 52, 135, var(--tw-text-opacity));
}

.text-khs-700 {
  --tw-text-opacity: 1;
  color: rgba(25, 44, 122, var(--tw-text-opacity));
}

.text-khs-800 {
  --tw-text-opacity: 1;
  color: rgba(20, 36, 109, var(--tw-text-opacity));
}

.text-khs-900 {
  --tw-text-opacity: 1;
  color: rgba(15, 28, 96, var(--tw-text-opacity));
}

.text-purple-900 {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}

.text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgba(15, 23, 42, var(--tw-text-opacity));
}

.text-warmgray-700 {
  --tw-text-opacity: 1;
  color: rgba(68, 64, 60, var(--tw-text-opacity));
}

.text-warmgray-800 {
  --tw-text-opacity: 1;
  color: rgba(41, 37, 36, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(234, 179, 8, var(--tw-text-opacity));
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(161, 98, 7, var(--tw-text-opacity));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(133, 77, 14, var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.opacity-70 {
  opacity: .7;
}

.opacity-75 {
  opacity: .75;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 8px 10px -6px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-khs-500 {
  --tw-shadow-color: #22398e;
  --tw-shadow: var(--tw-shadow-colored);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(212, 212, 212, var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

[x-cloak] {
  display: none;
}

.comment__button {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 57, 142, var(--tw-bg-opacity));
}

.htmx-indicator {
  opacity: 0;
  transition: opacity .5s ease-in;
}

.htmx-request .htmx-indicator, .htmx-request.htmx-indicator {
  opacity: 1;
}

.nominate_spinner {
  display: none;
}

.htmx-request .nominate_spinner, .htmx-request.nominate_spinner {
  display: inline;
}

@media (min-width: 768px) {
  .md\:prose-lg {
    font-size: 1.125rem;
    line-height: 1.77778;
  }

  .md\:prose-lg :where(p):not(:where([class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
  }

  .md\:prose-lg :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
    margin-top: 1.09091em;
    margin-bottom: 1.09091em;
    font-size: 1.22222em;
    line-height: 1.45455;
  }

  .md\:prose-lg :where(blockquote):not(:where([class~="not-prose"] *)) {
    margin-top: 1.66667em;
    margin-bottom: 1.66667em;
    padding-left: 1em;
  }

  .md\:prose-lg :where(h1):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: .833333em;
    font-size: 2.66667em;
    line-height: 1;
  }

  .md\:prose-lg :where(h2):not(:where([class~="not-prose"] *)) {
    margin-top: 1.86667em;
    margin-bottom: 1.06667em;
    font-size: 1.66667em;
    line-height: 1.33333;
  }

  .md\:prose-lg :where(h3):not(:where([class~="not-prose"] *)) {
    margin-top: 1.66667em;
    margin-bottom: .666667em;
    font-size: 1.33333em;
    line-height: 1.5;
  }

  .md\:prose-lg :where(h4):not(:where([class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: .444444em;
    line-height: 1.55556;
  }

  .md\:prose-lg :where(img):not(:where([class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .md\:prose-lg :where(video):not(:where([class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .md\:prose-lg :where(figure):not(:where([class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .md\:prose-lg :where(figure > *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:prose-lg :where(figcaption):not(:where([class~="not-prose"] *)) {
    margin-top: 1em;
    font-size: .888889em;
    line-height: 1.5;
  }

  .md\:prose-lg :where(code):not(:where([class~="not-prose"] *)) {
    font-size: .888889em;
  }

  .md\:prose-lg :where(h2 code):not(:where([class~="not-prose"] *)) {
    font-size: .866667em;
  }

  .md\:prose-lg :where(h3 code):not(:where([class~="not-prose"] *)) {
    font-size: .875em;
  }

  .md\:prose-lg :where(pre):not(:where([class~="not-prose"] *)) {
    border-radius: .375rem;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1em 1.5em;
    font-size: .888889em;
    line-height: 1.75;
  }

  .md\:prose-lg :where(ol):not(:where([class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
    padding-left: 1.55556em;
  }

  .md\:prose-lg :where(ul):not(:where([class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
    padding-left: 1.55556em;
  }

  .md\:prose-lg :where(li):not(:where([class~="not-prose"] *)) {
    margin-top: .666667em;
    margin-bottom: .666667em;
  }

  .md\:prose-lg :where(ol > li):not(:where([class~="not-prose"] *)) {
    padding-left: .444444em;
  }

  .md\:prose-lg :where(ul > li):not(:where([class~="not-prose"] *)) {
    padding-left: .444444em;
  }

  .md\:prose-lg :where(.md\:prose-lg > ul > li p):not(:where([class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .md\:prose-lg :where(.md\:prose-lg > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .md\:prose-lg :where(.md\:prose-lg > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .md\:prose-lg :where(.md\:prose-lg > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .md\:prose-lg :where(.md\:prose-lg > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .md\:prose-lg :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .md\:prose-lg :where(hr):not(:where([class~="not-prose"] *)) {
    margin-top: 3.11111em;
    margin-bottom: 3.11111em;
  }

  .md\:prose-lg :where(hr + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .md\:prose-lg :where(h2 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .md\:prose-lg :where(h3 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .md\:prose-lg :where(h4 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .md\:prose-lg :where(table):not(:where([class~="not-prose"] *)) {
    font-size: .888889em;
    line-height: 1.5;
  }

  .md\:prose-lg :where(thead th):not(:where([class~="not-prose"] *)) {
    padding-bottom: .75em;
    padding-left: .75em;
    padding-right: .75em;
  }

  .md\:prose-lg :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
    padding-left: 0;
  }

  .md\:prose-lg :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
    padding-right: 0;
  }

  .md\:prose-lg :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
    padding: .75em;
  }

  .md\:prose-lg :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
    padding-left: 0;
  }

  .md\:prose-lg :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
    padding-right: 0;
  }

  .md\:prose-lg :where(.md\:prose-lg > :first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .md\:prose-lg :where(.md\:prose-lg > :last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 0;
  }

  button.md\:is-sm {
    padding: .5rem .75rem;
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .lg\:prose-xl {
    font-size: 1.25rem;
    line-height: 1.8;
  }

  .lg\:prose-xl :where(p):not(:where([class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }

  .lg\:prose-xl :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.2em;
    line-height: 1.5;
  }

  .lg\:prose-xl :where(blockquote):not(:where([class~="not-prose"] *)) {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1.06667em;
  }

  .lg\:prose-xl :where(h1):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: .857143em;
    font-size: 2.8em;
    line-height: 1;
  }

  .lg\:prose-xl :where(h2):not(:where([class~="not-prose"] *)) {
    margin-top: 1.55556em;
    margin-bottom: .888889em;
    font-size: 1.8em;
    line-height: 1.11111;
  }

  .lg\:prose-xl :where(h3):not(:where([class~="not-prose"] *)) {
    margin-top: 1.6em;
    margin-bottom: .666667em;
    font-size: 1.5em;
    line-height: 1.33333;
  }

  .lg\:prose-xl :where(h4):not(:where([class~="not-prose"] *)) {
    margin-top: 1.8em;
    margin-bottom: .6em;
    line-height: 1.6;
  }

  .lg\:prose-xl :where(img):not(:where([class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .lg\:prose-xl :where(video):not(:where([class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .lg\:prose-xl :where(figure):not(:where([class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .lg\:prose-xl :where(figure > *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:prose-xl :where(figcaption):not(:where([class~="not-prose"] *)) {
    margin-top: 1em;
    font-size: .9em;
    line-height: 1.55556;
  }

  .lg\:prose-xl :where(code):not(:where([class~="not-prose"] *)) {
    font-size: .9em;
  }

  .lg\:prose-xl :where(h2 code):not(:where([class~="not-prose"] *)) {
    font-size: .861111em;
  }

  .lg\:prose-xl :where(h3 code):not(:where([class~="not-prose"] *)) {
    font-size: .9em;
  }

  .lg\:prose-xl :where(pre):not(:where([class~="not-prose"] *)) {
    border-radius: .5rem;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1.11111em 1.33333em;
    font-size: .9em;
    line-height: 1.77778;
  }

  .lg\:prose-xl :where(ol):not(:where([class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
    padding-left: 1.6em;
  }

  .lg\:prose-xl :where(ul):not(:where([class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
    padding-left: 1.6em;
  }

  .lg\:prose-xl :where(li):not(:where([class~="not-prose"] *)) {
    margin-top: .6em;
    margin-bottom: .6em;
  }

  .lg\:prose-xl :where(ol > li):not(:where([class~="not-prose"] *)) {
    padding-left: .4em;
  }

  .lg\:prose-xl :where(ul > li):not(:where([class~="not-prose"] *)) {
    padding-left: .4em;
  }

  .lg\:prose-xl :where(.lg\:prose-xl > ul > li p):not(:where([class~="not-prose"] *)) {
    margin-top: .8em;
    margin-bottom: .8em;
  }

  .lg\:prose-xl :where(.lg\:prose-xl > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .lg\:prose-xl :where(.lg\:prose-xl > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 1.2em;
  }

  .lg\:prose-xl :where(.lg\:prose-xl > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .lg\:prose-xl :where(.lg\:prose-xl > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 1.2em;
  }

  .lg\:prose-xl :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
    margin-top: .8em;
    margin-bottom: .8em;
  }

  .lg\:prose-xl :where(hr):not(:where([class~="not-prose"] *)) {
    margin-top: 2.8em;
    margin-bottom: 2.8em;
  }

  .lg\:prose-xl :where(hr + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .lg\:prose-xl :where(h2 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .lg\:prose-xl :where(h3 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .lg\:prose-xl :where(h4 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .lg\:prose-xl :where(table):not(:where([class~="not-prose"] *)) {
    font-size: .9em;
    line-height: 1.55556;
  }

  .lg\:prose-xl :where(thead th):not(:where([class~="not-prose"] *)) {
    padding-bottom: .888889em;
    padding-left: .666667em;
    padding-right: .666667em;
  }

  .lg\:prose-xl :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
    padding-left: 0;
  }

  .lg\:prose-xl :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
    padding-right: 0;
  }

  .lg\:prose-xl :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
    padding: .888889em .666667em;
  }

  .lg\:prose-xl :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
    padding-left: 0;
  }

  .lg\:prose-xl :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
    padding-right: 0;
  }

  .lg\:prose-xl :where(.lg\:prose-xl > :first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .lg\:prose-xl :where(.lg\:prose-xl > :last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 0;
  }
}

.last\:border-b-0:last-child {
  border-bottom-width: 0;
}

.focus-within\:ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.focus-within\:ring-inset:focus-within {
  --tw-ring-inset: inset;
}

.focus-within\:ring-khs-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 57, 142, var(--tw-ring-opacity));
}

.hover\:z-10:hover {
  z-index: 10;
}

.hover\:translate-y-0:hover {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:translate-y-0\.5:hover {
  --tw-translate-y: .125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-gray-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity));
}

.hover\:border-gray-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(229, 229, 229, var(--tw-border-opacity));
}

.hover\:border-khs-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(131, 149, 187, var(--tw-border-opacity));
}

.hover\:border-khs-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(30, 52, 135, var(--tw-border-opacity));
}

.hover\:bg-amber-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}

.hover\:bg-amber-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}

.hover\:bg-blue-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(115, 115, 115, var(--tw-bg-opacity));
}

.hover\:bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.hover\:bg-green-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}

.hover\:bg-khs-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(205, 213, 229, var(--tw-bg-opacity));
}

.hover\:bg-khs-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 233, 243, var(--tw-bg-opacity));
}

.hover\:bg-khs-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(25, 44, 122, var(--tw-bg-opacity));
}

.hover\:bg-khs-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 36, 109, var(--tw-bg-opacity));
}

.hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.hover\:bg-red-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.hover\:text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-text-opacity));
}

.hover\:text-gray-300:hover {
  --tw-text-opacity: 1;
  color: rgba(212, 212, 212, var(--tw-text-opacity));
}

.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgba(163, 163, 163, var(--tw-text-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(64, 64, 64, var(--tw-text-opacity));
}

.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgba(38, 38, 38, var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(23, 23, 23, var(--tw-text-opacity));
}

.hover\:text-green-100:hover {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}

.hover\:text-khs-100:hover {
  --tw-text-opacity: 1;
  color: rgba(205, 213, 229, var(--tw-text-opacity));
}

.hover\:text-khs-500:hover {
  --tw-text-opacity: 1;
  color: rgba(34, 57, 142, var(--tw-text-opacity));
}

.hover\:text-khs-600:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 52, 135, var(--tw-text-opacity));
}

.hover\:text-khs-700:hover {
  --tw-text-opacity: 1;
  color: rgba(25, 44, 122, var(--tw-text-opacity));
}

.hover\:text-khs-800:hover {
  --tw-text-opacity: 1;
  color: rgba(20, 36, 109, var(--tw-text-opacity));
}

.hover\:text-red-100:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:shadow-none:hover {
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:border-khs-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(34, 57, 142, var(--tw-border-opacity));
}

.focus\:bg-khs-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(15, 28, 96, var(--tw-bg-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.focus\:ring-amber-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}

.focus\:ring-gray-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(115, 115, 115, var(--tw-ring-opacity));
}

.focus\:ring-green-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}

.focus\:ring-khs-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 57, 142, var(--tw-ring-opacity));
}

.focus\:ring-khs-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 52, 135, var(--tw-ring-opacity));
}

.focus\:ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}

.focus\:ring-offset-1:focus {
  --tw-ring-offset-width: 1px;
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:ring-offset-khs-800:focus {
  --tw-ring-offset-color: #14246d;
}

.focus\:ring-offset-khs-900:focus {
  --tw-ring-offset-color: #0f1c60;
}

.disabled\:opacity-40:disabled {
  opacity: .4;
}

.group:hover .group-hover\:block {
  display: block;
}

.group:hover .group-hover\:hidden {
  display: none;
}

.group:hover .group-hover\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}

.group:hover .group-hover\:text-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-warmgray-700 {
  --tw-text-opacity: 1;
  color: rgba(68, 64, 60, var(--tw-text-opacity));
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.peer:disabled ~ .peer-disabled\:opacity-40 {
  opacity: .4;
}

@media (prefers-color-scheme: dark) {
  .dark\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(82, 82, 82, var(--tw-text-opacity));
  }

  .dark\:text-slate-200 {
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .dark\:text-slate-400 {
    --tw-text-opacity: 1;
    color: rgba(148, 163, 184, var(--tw-text-opacity));
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .dark\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
  }

  .dark\:ring-inset {
    --tw-ring-inset: inset;
  }

  .dark\:ring-white\/10 {
    --tw-ring-color: rgba(255, 255, 255, .1);
  }
}

@media print {
  .print\:\!block {
    display: block !important;
  }

  .print\:block {
    display: block;
  }

  .print\:\!hidden {
    display: none !important;
  }

  .print\:hidden {
    display: none;
  }

  .print\:h-auto {
    height: auto;
  }

  .print\:\!w-full {
    width: 100% !important;
  }

  .print\:max-w-screen-lg {
    max-width: 1024px;
  }

  .print\:break-after-page {
    break-after: page;
  }

  .print\:overflow-auto {
    overflow: auto;
  }

  .print\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .print\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .print\:text-khs-400 {
    --tw-text-opacity: 1;
    color: rgba(90, 116, 166, var(--tw-text-opacity));
  }

  .print\:text-khs-800 {
    --tw-text-opacity: 1;
    color: rgba(20, 36, 109, var(--tw-text-opacity));
  }

  .print\:shadow-none {
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }
}

@media (min-width: 640px) {
  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .sm\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:ml-3 {
    margin-left: .75rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-12 {
    margin-top: 3rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline {
    display: inline;
  }

  .sm\:flex {
    display: -ms-flexbox;
    display: flex;
  }

  .sm\:inline-flex {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-10 {
    height: 2.5rem;
  }

  .sm\:w-10 {
    width: 2.5rem;
  }

  .sm\:w-72 {
    width: 18rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-3xl {
    max-width: 48rem;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:max-w-md {
    max-width: 28rem;
  }

  .sm\:max-w-screen-lg {
    max-width: 1024px;
  }

  .sm\:max-w-xl {
    max-width: 36rem;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:flex-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .sm\:flex-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .sm\:flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .sm\:items-start {
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .sm\:items-center {
    -ms-flex-align: center;
    align-items: center;
  }

  .sm\:justify-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:gap-px {
    gap: 1px;
  }

  .sm\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .sm\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }

  .sm\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-divide-opacity));
  }

  .sm\:overflow-hidden {
    overflow: hidden;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:rounded-md {
    border-radius: .375rem;
  }

  .sm\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sm\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-2 {
    padding: .5rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .sm\:pt-1 {
    padding-top: .25rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:shadow-none {
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }

  .sm\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }

  @media print {
    .sm\:print\:px-0 {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media (min-width: 768px) {
  .md\:order-first {
    -ms-flex-order: -9999;
    order: -9999;
  }

  .md\:order-last {
    -ms-flex-order: 9999;
    order: 9999;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:-mr-1 {
    margin-right: -.25rem;
  }

  .md\:ml-1 {
    margin-left: .25rem;
  }

  .md\:ml-2 {
    margin-left: .5rem;
  }

  .md\:ml-auto {
    margin-left: auto;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:flex {
    display: -ms-flexbox;
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:max-w-\[65ch\] {
    max-width: 65ch;
  }

  .md\:flex-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:flex-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .md\:items-end {
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .md\:justify-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:gap-x-4 {
    column-gap: 1rem;
  }

  .md\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .md\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:pl-4 {
    padding-left: 1rem;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-justify {
    text-align: justify;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1024px) {
  .lg\:order-first {
    -ms-flex-order: -9999;
    order: -9999;
  }

  .lg\:order-last {
    -ms-flex-order: 9999;
    order: 9999;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .lg\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .lg\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:ml-4 {
    margin-left: 1rem;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: -ms-flexbox;
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-80 {
    width: 20rem;
  }

  .lg\:max-w-7xl {
    max-width: 80rem;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:max-w-screen-2xl {
    max-width: 1536px;
  }

  .lg\:flex-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .lg\:columns-2 {
    columns: 2;
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:justify-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .lg\:gap-12 {
    gap: 3rem;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:gap-x-4 {
    column-gap: 1rem;
  }

  .lg\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .lg\:border-none {
    border-style: none;
  }

  .lg\:bg-transparent {
    background-color: rgba(0, 0, 0, 0);
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:pb-16 {
    padding-bottom: 4rem;
  }

  .lg\:pb-2 {
    padding-bottom: .5rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  @media print {
    .lg\:print\:px-0 {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media (min-width: 1280px) {
  .xl\:ml-0 {
    margin-left: 0;
  }

  .xl\:mr-\[15\.5rem\] {
    margin-right: 15.5rem;
  }

  .xl\:w-1\/3 {
    width: 33.3333%;
  }

  .xl\:w-2\/3 {
    width: 66.6667%;
  }

  .xl\:w-\[30rem\] {
    width: 30rem;
  }

  .xl\:max-w-none {
    max-width: none;
  }

  .xl\:gap-x-8 {
    column-gap: 2rem;
  }

  .xl\:pl-6 {
    padding-left: 1.5rem;
  }

  .xl\:pr-16 {
    padding-right: 4rem;
  }

  .xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:block {
    display: block;
  }

  .\32 xl\:w-full {
    width: 100%;
  }

  .\32 xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .\32 xl\:gap-16 {
    gap: 4rem;
  }

  .\32 xl\:gap-x-2 {
    column-gap: .5rem;
  }

  .\32 xl\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }
}

:root {
  --ts-pr-clear-button: 0;
  --ts-pr-caret: 0;
  --ts-pr-min: .75rem;
}

.ts-wrapper.single .ts-control, .ts-wrapper.single .ts-control input {
  cursor: pointer;
}

.ts-control {
  padding-right: max(var(--ts-pr-min), var(--ts-pr-clear-button)  + var(--ts-pr-caret)) !important;
}

.ts-wrapper.plugin-drag_drop.multi > .ts-control > div.ui-sortable-placeholder {
  box-shadow: inset 0 0 12px 4px #fff;
  visibility: visible !important;
  background: rgba(0, 0, 0, .06) !important;
  border: 0 !important;
}

.ts-wrapper.plugin-drag_drop .ui-sortable-placeholder:after {
  content: "!";
  visibility: hidden;
}

.ts-wrapper.plugin-drag_drop .ui-sortable-helper {
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}

.plugin-checkbox_options .option input {
  margin-right: .5rem;
}

.plugin-clear_button {
  --ts-pr-clear-button: 1em;
}

.plugin-clear_button .clear-button {
  cursor: pointer;
  opacity: 0;
  transition: opacity .5s;
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  background: none !important;
  margin-right: 0 !important;
}

.plugin-clear_button.form-select .clear-button, .plugin-clear_button.single .clear-button {
  right: max(var(--ts-pr-caret), 8px);
}

.plugin-clear_button.focus.has-items .clear-button, .plugin-clear_button:not(.disabled):hover.has-items .clear-button {
  opacity: 1;
}

.ts-wrapper .dropdown-header {
  background: #f8f8f8;
  border-bottom: 1px solid #d0d0d0;
  border-radius: 3px 3px 0 0;
  padding: 10px 8px;
  position: relative;
}

.ts-wrapper .dropdown-header-close {
  color: #303030;
  opacity: .4;
  margin-top: -12px;
  line-height: 20px;
  position: absolute;
  top: 50%;
  right: 8px;
  font-size: 20px !important;
}

.ts-wrapper .dropdown-header-close:hover {
  color: #000;
}

.plugin-dropdown_input.focus.dropdown-active .ts-control {
  box-shadow: none;
  border: 1px solid #d0d0d0;
}

.plugin-dropdown_input .dropdown-input {
  box-shadow: none;
  width: 100%;
  background: none;
  border: 0 solid #d0d0d0;
  border-bottom-width: 1px;
  padding: 8px;
  display: block;
}

.plugin-dropdown_input .items-placeholder {
  width: 100%;
  box-shadow: none !important;
  border: 0 !important;
}

.plugin-dropdown_input.dropdown-active .items-placeholder, .plugin-dropdown_input.has-items .items-placeholder {
  display: none !important;
}

.ts-wrapper.plugin-input_autogrow.has-items .ts-control > input {
  min-width: 0;
}

.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input {
  min-width: 4px;
  -ms-flex: none;
  flex: none;
}

.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input::placeholder {
  color: rgba(0, 0, 0, 0);
}

.ts-dropdown.plugin-optgroup_columns .ts-dropdown-content {
  display: -ms-flexbox;
  display: flex;
}

.ts-dropdown.plugin-optgroup_columns .optgroup {
  min-width: 0;
  border-top: 0;
  border-right: 1px solid #f2f2f2;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

.ts-dropdown.plugin-optgroup_columns .optgroup:last-child {
  border-right: 0;
}

.ts-dropdown.plugin-optgroup_columns .optgroup:before {
  display: none;
}

.ts-dropdown.plugin-optgroup_columns .optgroup-header {
  border-top: 0;
}

.ts-wrapper.plugin-remove_button .item {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-right: 0 !important;
}

.ts-wrapper.plugin-remove_button .item .remove {
  box-sizing: border-box;
  color: inherit;
  vertical-align: middle;
  border-radius: 0 2px 2px 0;
  padding: 0 6px;
  text-decoration: none;
  display: inline-block;
}

.ts-wrapper.plugin-remove_button .item .remove:hover {
  background: rgba(0, 0, 0, .05);
}

.ts-wrapper.plugin-remove_button.disabled .item .remove:hover {
  background: none;
}

.ts-wrapper.plugin-remove_button .remove-single {
  font-size: 23px;
  position: absolute;
  top: 0;
  right: 0;
}

.ts-wrapper.plugin-remove_button:not(.rtl) .item .remove {
  border-left: 1px solid #d0d0d0;
  margin-left: 6px;
}

.ts-wrapper.plugin-remove_button:not(.rtl) .item.active .remove {
  border-left-color: #cacaca;
}

.ts-wrapper.plugin-remove_button:not(.rtl).disabled .item .remove {
  border-left-color: #fff;
}

.ts-wrapper.plugin-remove_button.rtl .item .remove {
  border-right: 1px solid #d0d0d0;
  margin-right: 6px;
}

.ts-wrapper.plugin-remove_button.rtl .item.active .remove {
  border-right-color: #cacaca;
}

.ts-wrapper.plugin-remove_button.rtl.disabled .item .remove {
  border-right-color: #fff;
}

.ts-wrapper {
  position: relative;
}

.ts-control, .ts-control input, .ts-dropdown {
  font-smoothing: inherit;
  color: #303030;
  font-family: inherit;
  font-size: 13px;
  line-height: 18px;
}

.ts-control, .ts-wrapper.single.input-active .ts-control {
  cursor: text;
  background: #fff;
}

.ts-control {
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  z-index: 1;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 8px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: hidden;
}

.ts-wrapper.multi.has-items .ts-control {
  padding: 6px 8px 3px;
}

.full .ts-control {
  background-color: #fff;
}

.disabled .ts-control, .disabled .ts-control * {
  cursor: default !important;
}

.focus .ts-control {
  box-shadow: none;
}

.ts-control > * {
  vertical-align: baseline;
  display: inline-block;
}

.ts-wrapper.multi .ts-control > div {
  color: #303030;
  cursor: pointer;
  background: #f2f2f2;
  border: 0 solid #d0d0d0;
  margin: 0 3px 3px 0;
  padding: 2px 6px;
}

.ts-wrapper.multi .ts-control > div.active {
  color: #303030;
  background: #e8e8e8;
  border: 0 solid #cacaca;
}

.ts-wrapper.multi.disabled .ts-control > div, .ts-wrapper.multi.disabled .ts-control > div.active {
  color: #7d7d7d;
  background: #fff;
  border: 0 solid #fff;
}

.ts-control > input {
  min-width: 7rem;
  -ms-flex: auto;
  flex: auto;
  box-shadow: none !important;
  line-height: inherit !important;
  max-height: none !important;
  max-width: 100% !important;
  min-height: 0 !important;
  text-indent: 0 !important;
  -webkit-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
  background: none !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  display: inline-block !important;
}

.ts-control > input::-ms-clear {
  display: none;
}

.ts-control > input:focus {
  outline: none !important;
}

.has-items .ts-control > input {
  margin: 0 4px !important;
}

.ts-control.rtl {
  text-align: right;
}

.ts-control.rtl.single .ts-control:after {
  left: 15px;
  right: auto;
}

.ts-control.rtl .ts-control > input {
  margin: 0 4px 0 -2px !important;
}

.disabled .ts-control {
  opacity: .5;
  background-color: #fafafa;
}

.input-hidden .ts-control > input {
  opacity: 0;
  position: absolute;
  left: -10000px;
}

.ts-dropdown {
  box-sizing: border-box;
  width: 100%;
  z-index: 10;
  background: #fff;
  border: 1px solid #d0d0d0;
  border-top: 0;
  border-radius: 0 0 3px 3px;
  margin: .25rem 0 0;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
}

.ts-dropdown [data-selectable] {
  cursor: pointer;
  overflow: hidden;
}

.ts-dropdown [data-selectable] .highlight {
  background: rgba(125, 168, 208, .2);
  border-radius: 1px;
}

.ts-dropdown .create, .ts-dropdown .no-results, .ts-dropdown .optgroup-header, .ts-dropdown .option {
  padding: 5px 8px;
}

.ts-dropdown .option, .ts-dropdown [data-disabled], .ts-dropdown [data-disabled] [data-selectable].option {
  cursor: inherit;
  opacity: .5;
}

.ts-dropdown [data-selectable].option {
  cursor: pointer;
  opacity: 1;
}

.ts-dropdown .optgroup:first-child .optgroup-header {
  border-top: 0;
}

.ts-dropdown .optgroup-header {
  color: #303030;
  cursor: default;
  background: #fff;
}

.ts-dropdown .active {
  color: #495c68;
  background-color: #f5fafd;
}

.ts-dropdown .active.create {
  color: #495c68;
}

.ts-dropdown .create {
  color: rgba(48, 48, 48, .5);
}

.ts-dropdown .spinner {
  height: 30px;
  width: 30px;
  margin: 5px 8px;
  display: inline-block;
}

.ts-dropdown .spinner:after {
  content: " ";
  height: 24px;
  width: 24px;
  border: 5px solid #d0d0d0;
  border-color: #d0d0d0 rgba(0, 0, 0, 0);
  border-radius: 50%;
  margin: 3px;
  animation: 1.2s linear infinite lds-dual-ring;
  display: block;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.ts-dropdown-content {
  overflow-scrolling: touch;
  max-height: 200px;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: auto;
}

.ts-hidden-accessible {
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  border: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.ql-container {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  font-family: Tahoma, Segoe UI, Geneva, Verdana, Helvetica, Arial, sans-serif;
  font-size: 13px;
  position: relative;
}

.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}

.ql-container.ql-disabled .ql-editor ul[data-checked] > li:before {
  pointer-events: none;
}

.ql-clipboard {
  height: 1px;
  position: absolute;
  top: 50%;
  left: -100000px;
  overflow-y: hidden;
}

.ql-clipboard p {
  margin: 0;
  padding: 0;
}

.ql-editor {
  box-sizing: border-box;
  height: 100%;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  padding: 12px 15px;
  line-height: 1.42;
  overflow-y: auto;
}

.ql-editor > * {
  cursor: text;
}

.ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  margin: 0;
  padding: 0;
}

.ql-editor ol, .ql-editor ul {
  padding-left: 1.5em;
}

.ql-editor ol > li, .ql-editor ul > li {
  list-style-type: none;
}

.ql-editor ul > li:before {
  content: "•";
}

.ql-editor ul[data-checked="true"], .ql-editor ul[data-checked="false"] {
  pointer-events: none;
}

.ql-editor ul[data-checked="true"] > li *, .ql-editor ul[data-checked="false"] > li * {
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li:before, .ql-editor ul[data-checked="false"] > li:before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li:before {
  content: "☑";
}

.ql-editor ul[data-checked="false"] > li:before {
  content: "☐";
}

.ql-editor li:before {
  white-space: nowrap;
  width: 1.2em;
  display: inline-block;
}

.ql-editor li:not(.ql-direction-rtl):before {
  text-align: right;
  margin-left: -1.5em;
  margin-right: .3em;
}

.ql-editor li.ql-direction-rtl:before {
  margin-left: .3em;
  margin-right: -1.5em;
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}

.ql-editor ol li.ql-direction-rtl, .ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}

.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}

.ql-editor ol li:before {
  content: counter(list-0, decimal) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}

.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
}

.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
}

.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}

.ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
}

.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
}

.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
}

.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}

.ql-editor ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
}

.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}

.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
}

.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-8 {
  counter-reset: list-9;
}

.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}

.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". ";
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}

.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}

.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}

.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}

.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}

.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}

.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}

.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}

.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}

.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}

.ql-editor .ql-video {
  max-width: 100%;
  display: block;
}

.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}

.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}

.ql-editor .ql-bg-black {
  background-color: #000;
}

.ql-editor .ql-bg-red {
  background-color: #e60000;
}

.ql-editor .ql-bg-orange {
  background-color: #f90;
}

.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}

.ql-editor .ql-bg-green {
  background-color: #008a00;
}

.ql-editor .ql-bg-blue {
  background-color: #06c;
}

.ql-editor .ql-bg-purple {
  background-color: #93f;
}

.ql-editor .ql-color-white {
  color: #fff;
}

.ql-editor .ql-color-red {
  color: #e60000;
}

.ql-editor .ql-color-orange {
  color: #f90;
}

.ql-editor .ql-color-yellow {
  color: #ff0;
}

.ql-editor .ql-color-green {
  color: #008a00;
}

.ql-editor .ql-color-blue {
  color: #06c;
}

.ql-editor .ql-color-purple {
  color: #93f;
}

.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.ql-editor .ql-size-small {
  font-size: .75em;
}

.ql-editor .ql-size-large {
  font-size: 1.5em;
}

.ql-editor .ql-size-huge {
  font-size: 2.5em;
}

.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}

.ql-editor .ql-align-center {
  text-align: center;
}

.ql-editor .ql-align-justify {
  text-align: justify;
}

.ql-editor .ql-align-right {
  text-align: right;
}

.ql-editor.ql-blank:before {
  color: rgba(0, 0, 0, .4);
  content: attr(data-placeholder);
  pointer-events: none;
  font-style: italic;
  position: absolute;
  left: 15px;
  right: 15px;
}

.ql-snow.ql-toolbar:after, .ql-snow .ql-toolbar:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
  cursor: pointer;
  float: left;
  height: 24px;
  width: 28px;
  background: none;
  border: none;
  padding: 3px 5px;
  display: inline-block;
}

.ql-snow.ql-toolbar button svg, .ql-snow .ql-toolbar button svg {
  float: left;
  height: 100%;
}

.ql-snow.ql-toolbar button:active:hover, .ql-snow .ql-toolbar button:active:hover {
  outline: none;
}

.ql-snow.ql-toolbar input.ql-image[type="file"], .ql-snow .ql-toolbar input.ql-image[type="file"] {
  display: none;
}

.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #06c;
}

.ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #06c;
}

.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #06c;
}

@media (pointer: coarse) {
  .ql-snow.ql-toolbar button:hover:not(.ql-active), .ql-snow .ql-toolbar button:hover:not(.ql-active) {
    color: #444;
  }

  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
    fill: #444;
  }

  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
    stroke: #444;
  }
}

.ql-snow, .ql-snow * {
  box-sizing: border-box;
}

.ql-snow .ql-hidden {
  display: none;
}

.ql-snow .ql-out-bottom, .ql-snow .ql-out-top {
  visibility: hidden;
}

.ql-snow .ql-tooltip {
  position: absolute;
  transform: translateY(10px);
}

.ql-snow .ql-tooltip a {
  cursor: pointer;
  text-decoration: none;
}

.ql-snow .ql-tooltip.ql-flip {
  transform: translateY(-10px);
}

.ql-snow .ql-formats {
  vertical-align: middle;
  display: inline-block;
}

.ql-snow .ql-formats:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow .ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}

.ql-snow .ql-stroke-miter {
  fill: none;
  stroke: #444;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
  fill: #444;
}

.ql-snow .ql-empty {
  fill: none;
}

.ql-snow .ql-even {
  fill-rule: evenodd;
}

.ql-snow .ql-thin, .ql-snow .ql-stroke.ql-thin {
  stroke-width: 1px;
}

.ql-snow .ql-transparent {
  opacity: .4;
}

.ql-snow .ql-direction svg:last-child {
  display: none;
}

.ql-snow .ql-direction.ql-active svg:last-child {
  display: inline;
}

.ql-snow .ql-direction.ql-active svg:first-child {
  display: none;
}

.ql-snow .ql-editor h1 {
  font-size: 2em;
}

.ql-snow .ql-editor h2 {
  font-size: 1.5em;
}

.ql-snow .ql-editor h3 {
  font-size: 1.17em;
}

.ql-snow .ql-editor h4 {
  font-size: 1em;
}

.ql-snow .ql-editor h5 {
  font-size: .83em;
}

.ql-snow .ql-editor h6 {
  font-size: .67em;
}

.ql-snow .ql-editor a {
  text-decoration: underline;
}

.ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 16px;
}

.ql-snow .ql-editor code, .ql-snow .ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px;
}

.ql-snow .ql-editor pre {
  white-space: pre-wrap;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
}

.ql-snow .ql-editor code {
  padding: 2px 4px;
  font-size: 85%;
}

.ql-snow .ql-editor pre.ql-syntax {
  color: #f8f8f2;
  background-color: #23241f;
  overflow: visible;
}

.ql-snow .ql-editor img {
  max-width: 100%;
}

.ql-snow .ql-picker {
  color: #444;
  float: left;
  height: 24px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.ql-snow .ql-picker-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding-left: 8px;
  padding-right: 2px;
  display: inline-block;
  position: relative;
}

.ql-snow .ql-picker-label:before {
  line-height: 22px;
  display: inline-block;
}

.ql-snow .ql-picker-options {
  min-width: 100%;
  white-space: nowrap;
  background-color: #fff;
  padding: 4px 8px;
  display: none;
  position: absolute;
}

.ql-snow .ql-picker-options .ql-picker-item {
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #ccc;
  z-index: 2;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #ccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  z-index: 1;
  margin-top: -1px;
  display: block;
  top: 100%;
}

.ql-snow .ql-color-picker, .ql-snow .ql-icon-picker {
  width: 28px;
}

.ql-snow .ql-color-picker .ql-picker-label, .ql-snow .ql-icon-picker .ql-picker-label {
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-label svg, .ql-snow .ql-icon-picker .ql-picker-label svg {
  right: 4px;
}

.ql-snow .ql-icon-picker .ql-picker-options {
  padding: 4px 0;
}

.ql-snow .ql-icon-picker .ql-picker-item {
  height: 24px;
  width: 24px;
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-options {
  width: 152px;
  padding: 3px 5px;
}

.ql-snow .ql-color-picker .ql-picker-item {
  float: left;
  height: 16px;
  width: 16px;
  border: 1px solid rgba(0, 0, 0, 0);
  margin: 2px;
  padding: 0;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  width: 18px;
  margin-top: -9px;
  position: absolute;
  top: 50%;
  right: 0;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=""]):before {
  content: attr(data-label);
}

.ql-snow .ql-picker.ql-header {
  width: 98px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label:before, .ql-snow .ql-picker.ql-header .ql-picker-item:before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  content: "Überschrift 1";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  content: "Überschrift 2";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  content: "Überschrift 3";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  content: "Überschrift 4";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  content: "Überschrift 5";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  content: "Überschrift 6";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  font-size: 2em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  font-size: 1.5em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  font-size: 1.17em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  font-size: 1em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  font-size: .83em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  font-size: .67em;
}

.ql-snow .ql-picker.ql-font {
  width: 108px;
}

.ql-snow .ql-picker.ql-font .ql-picker-label:before, .ql-snow .ql-picker.ql-font .ql-picker-item:before {
  content: "Sans Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]:before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  content: "Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]:before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  content: "Monospace";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  font-family: Georgia, Times New Roman, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  font-family: Monaco, Courier New, monospace;
}

.ql-snow .ql-picker.ql-size {
  width: 98px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label:before, .ql-snow .ql-picker.ql-size .ql-picker-item:before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  content: "Klein";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  content: "Groß";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  content: "Riesig";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  font-size: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  font-size: 32px;
}

.ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background-color: #fff;
}

.ql-snow .ql-color-picker.ql-color .ql-picker-item {
  background-color: #000;
}

.ql-toolbar.ql-snow {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  padding: 6px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}

.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid rgba(0, 0, 0, 0);
}

.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: 0 2px 8px rgba(0, 0, 0, .2);
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label, .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc;
}

.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected, .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  border-color: #000;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0;
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.ql-snow .ql-tooltip {
  color: #444;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 12px;
  box-shadow: 0 0 5px #ddd;
}

.ql-snow .ql-tooltip:before {
  content: "URL aufrufen:";
  margin-right: 8px;
  line-height: 26px;
}

.ql-snow .ql-tooltip input[type="text"] {
  height: 26px;
  width: 170px;
  border: 1px solid #ccc;
  margin: 0;
  padding: 3px 5px;
  font-size: 13px;
  display: none;
}

.ql-snow .ql-tooltip a.ql-preview {
  max-width: 200px;
  text-overflow: ellipsis;
  vertical-align: top;
  display: inline-block;
  overflow-x: hidden;
}

.ql-snow .ql-tooltip a.ql-action:after {
  content: "Ändern";
  border-right: 1px solid #ccc;
  margin-left: 16px;
  padding-right: 8px;
}

.ql-snow .ql-tooltip a.ql-remove:before {
  content: "Entfernen";
  margin-left: 8px;
}

.ql-snow .ql-tooltip a {
  line-height: 26px;
}

.ql-snow .ql-tooltip.ql-editing a.ql-preview, .ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}

.ql-snow .ql-tooltip.ql-editing input[type="text"] {
  display: inline-block;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action:after {
  content: "Speichern";
  border-right: 0;
  padding-right: 0;
}

.ql-snow .ql-tooltip[data-mode="link"]:before {
  content: "Link hinzufügen:";
}

.ql-snow .ql-tooltip[data-mode="formula"]:before {
  content: "Formel einfügen:";
}

.ql-snow .ql-tooltip[data-mode="video"]:before {
  content: "Video einfügen:";
}

.ql-snow a {
  color: #06c;
}

.ql-container.ql-snow {
  border: 1px solid #ccc;
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  color: #fff;
  white-space: normal;
  background-color: #333;
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -7px;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 5px 9px;
  position: relative;
}

/*# sourceMappingURL=index.7e7336be.css.map */
